const skills = {
    languages: [
        { name: "Python", icon: "fa-brands fa-python", lvl: "75%" },
        { name: "JavaScript", icon: "fa-brands fa-js", lvl: "80%" },
        { name: "HTML/CSS", icon: "fa-brands fa-html5", lvl: "80%" },
        { name: "Java", icon: "fa-brands fa-java", lvl: "50%" },
        { name: "SQL", icon: "fa-solid fa-database", lvl: "90%" },
    ],

    technologies: [
        { name: "React", icon: "fa-brands fa-react", lvl: "[80%]" },
        { name: "Spring Boot", icon: "fa-solid fa-power-off", lvl: "80%" },
        { name: "Node", icon: "fa-brands fa-node", lvl: "80%" },
        { name: "Android", icon: "fa-brands fa-android", lvl: "80%" },
        { name: "REST Api", icon: "fa-solid fa-gear", lvl: "75%" },
    ],
};

export default skills;
